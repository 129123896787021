import { AppBar, Toolbar, Typography, Button } from '@mui/material'
// import { makeStyles } from '@mui/styles'
import React from 'react'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AppleIcon from '@mui/icons-material/Apple';

// const useStyles = makeStyles(() => ({
//     typographyStyles: {
//       flex: 1
//     }
//   }));

function Header() {
    // const classes = useStyles();
    const data = process.env.REACT_APP_HELLO_WORLD;
    // const data = process.env.NODE_ENV;
    // console.log(data);
    return (
        <AppBar position="static">
            <Toolbar>
                <Typography /*className={classes.typographyStyles}*/sx={{ flexGrow: 1 }}>
                    {data}
                </Typography>
                <AppleIcon />
                <Button color="inherit">Login</Button>
            </Toolbar>
        </AppBar>
    )
}

export default Header