import React , {useState, useEffect} from 'react'
import BodyCard from './BodyCard'
import {Grid} from '@mui/material'

import axios from 'axios'; // 追加

const titles = [
    {title : "タイトル #1"},
    {title : "タイトル #2"},
    {title : "タイトル #3"},
]



function Content() {
    const [post, setPosts] = useState([])

    useEffect(() => {
        axios.get('https://jsonplaceholder.typicode.com/posts')
        .then(res => {
            setPosts(res.data)
        })
    }, [])


    const getCardContent = getObj => {
        return (
            <Grid item xs={12} sm={4}>
                <BodyCard {...getObj} />
            </Grid>
        );
    };

    return (
        <Grid container spacing={2}>
            {titles.map(contentObj => getCardContent(contentObj))}
        </Grid>
    )
}

export default Content