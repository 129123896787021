import './App.css';

import * as React from 'react';
import Header from './components/Header'; // ヘッダ
import Content from './components/Content'; // コンテンツ
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {useState, useRef} from 'react';
import TodoList from './TodoList';
import {v4 as uuidv4} from 'uuid';

function App() {
  const [todos, setTodos] = useState([]);

  const todoNameRef = useRef();
  const handleAddTodo = ()=>{
    const value = todoNameRef.current.value;
    console.log('todoNameRef.current.value = ',value);
    if( value === "")return;
    setTodos((prevTodos)=>{
      return [...prevTodos,{id:uuidv4(), name:value, completed:false}];
    });
    todoNameRef.current.value = "";
  };

  const toggleTodo = (id)=>{
    const newTodos = [...todos];
    console.log(newTodos);
    const todo = newTodos.find((todo)=>(todo.id === id));
    todo.completed = !todo.completed;
    //console.log('toggleTodo', todo.id, todo.name);
    setTodos(newTodos);
  };

  const handleClear = ()=>{
    const newTodos = todos.filter((todo)=> (false === todo.completed));
    console.log(newTodos);
    setTodos(newTodos);
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <Header />
      </Grid>
      <Grid item>
        <TodoList todos={todos} toggleTodo={toggleTodo}/>
        <input type="text" ref={todoNameRef}/>
        <button onClick={handleAddTodo}>タスクを追加</button>
        <button onClick={handleClear}>完了したタスクの削除</button>
        <div>
          残りのタスク:{todos.filter((todo)=>!todo.completed).length}
        </div>
      </Grid>
      <Grid item container>
          {/* <Grid sm={2} />
        <Grid xs={12} sm={8}>
          <Content />
        </Grid>
        <Grid sm={2} /> */}
      </Grid>
    </Grid>
  );
}

export default App;