import React from 'react'

const Todo = (props) => {

    const handleTodoClick = ()=>
    {
        props.toggleTodo(props.todo.id);
        // console.log(todo.id);
    };

  return (
    <div>
        <label>
            <input type="checkbox" checked={props.todo.completed} readOnly onChange={handleTodoClick}/>
        </label>
        {props.todo.name}
    </div>
  )
}

export default Todo